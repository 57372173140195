<template>
    <div>
        <simple-accordion :always-open="true">
            <template #accordionTitle>
                <span class="me-3">Définition des formulaires</span>
                <button class="btn btn-primary btn-sm" @click="onPLusClicked">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
                <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
            </template>
            <template #accordionContent>
                <div>
                    <vue-good-table
                        :selected-rows="[]"
                        :columns="columns"
                        :rows="$store.state.adminForm.formDefinitions"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'g2_inter_code', type: 'asc' },
                        }"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Téléchargement...</span>
                        </template>
                        <template #table-row="props">
                            <div v-if="props.column.field === 'actions'" class="uk-text-center">
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(props.row)"></i>
                                <i
                                    v-if="props.row.deletable"
                                    class="fa-solid fa-trash"
                                    @click="onDelete(props.row)"
                                ></i>
                                <i
                                    class="fa-solid fa-eye"
                                    @click="$router.push({ name: 'form_view', params: { formId: props.row.id } })"
                                ></i>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFormDefinition" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleModal }}</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="g2_inter_code">Code type d'intervention G2</label>
                        <input id="g2_inter_code" class="uk-input" type="text" v-model="form.g2_inter_code" required />
                    </div>
                    <div class="uk-margin">
                        <label for="wording">Libellé</label>
                        <input id="wording" class="uk-input" type="text" v-model="form.wording" required />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer cette définition ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onDeleteOk">
                    Oui
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            titleModal: '',
            form: {
                g2_inter_code: '',
                wording: '',
            },
            columns: [
                {
                    label: 'Code type d`intervention G2',
                    field: 'g2_inter_code',
                },
                {
                    label: 'Libellé',
                    field: 'wording',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ],
            selected: [],
            isUpdate: false,
        }
    },
    methods: {
        clearInput() {
            this.form = {
                g2_inter_code: '',
                wording: '',
            }
        },
        onPLusClicked() {
            this.titleModal = 'Nouveau formulaire'
            this.isUpdate = false
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormDefinition.$el).show()
        },
        onSave() {
            if (!this.form.g2_inter_code || !this.form.wording) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir tous les champs')
                return
            }
            const data = {
                g2_inter_code: this.form.g2_inter_code,
                wording: this.form.wording,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.loading = true
            this.$http
                .updateFormDefinition(this.selected[0].id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormDefinition.$el).hide()
                    this.$store.dispatch('adminForm/setFormDefinition', res)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        add(data) {
            this.loading = true
            this.$http
                .addFormDefinition(data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormDefinition.$el).hide()
                    this.$store.dispatch('adminForm/setFormDefinition', res)
                    this.$store.dispatch('notifications/showInfo', 'Votre formulaire est bien créé')
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onDelete(props) {
            this.selected[0] = props
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onUpdate(props) {
            this.titleModal = 'Modification formulaire'
            this.selected[0] = props
            this.isUpdate = true
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewFormDefinition.$el).show()
        },
        async getAllFormDefinition() {
            this.loading = true
            this.$http
                .getAllFormDefinition()
                .then((res) => {
                    this.$store.dispatch('adminForm/setFormDefinition', res)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateAll() {
            this.getAllFormDefinition()
        },
        onDeleteOk() {
            this.loading = true
            this.$http
                .deleteFormDefinition(this.selected[0].id)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.updateAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillInput(data) {
            this.form = {
                wording: data.wording,
                g2_inter_code: data.g2_inter_code,
            }
        },
    },
    mounted() {
        this.getAllFormDefinition()
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

i {
    margin: 10px;
    cursor: pointer;
}
</style>
