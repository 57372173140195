<template>
    <div class="m-3">
        <FormDefinitionAccordionVue class="mb-3"></FormDefinitionAccordionVue>
    </div>
</template>

<script>
import FormDefinitionAccordionVue from '../components/forms/FormDefinitionAccordion.vue'

export default {
    components: {
        FormDefinitionAccordionVue,
    },
}
</script>

<style></style>
